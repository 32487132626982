'use client';

import Link from 'next/link';
import Image from 'next/image';
import { LuChevronDown, LuSearch } from 'react-icons/lu';

import type { AgencyMenu } from '@homeflow/next/models';
import { ButtonStyle } from '@homeflow/next/state';

import { SerializableAgency } from '@/serializers/agency';

import Container from '@rigel/components/container.component';
import LinkButton from '@rigel/components/buttons/link-button.component';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  menu: AgencyMenu;
  agency: SerializableAgency;
  showSearch?: boolean;
}

const CUSTOM_BUTTON_SIZES =
  '@lg:max-3xl:px-4 @lg:max-3xl:py-2 @lg:max-xl:text-2xs @xl:max-3xl:text-sm';

export default function DesktopNav({ menu, agency }: Props) {
  if (!menu) return null;

  const { menu_items: menuItems } = menu;

  return (
    <nav className="rigel-desktop-nav relative hidden @2xl:block my-10">
      <Container className="flex flex-row justify-between items-center">
        {agency.logo && (
          <Link prefetch={false} className="mr-auto" href="/">
            <Image
              src={agency.logo}
              alt="Logo"
              width={350}
              height={65}
              className="max-w-[160px] max-h-[45px] @xl:max-w-[210px] @xl:max-h-[55px] @3xl:max-w-[210px] @3xl:max-h-[65px]w-auto h-auto object-contain"
              priority
            />
          </Link>
        )}

        {/* Primary menu items */}
        <div className="flex items-end relative print:hidden">
          <div className="rigel-desktop-nav__primary-menu-items flex gap-6 @xl:gap-7 @3xl:gap-8 items-center">
            {menuItems?.map((item) => (
              <div key={item.name} className="rigel-desktop-nav__primary-menu-item relative group">
                <Link
                  prefetch={false}
                  className="flex flex-row gap-1 items-center text-2xs @xl:text-sm @3xl:text-base uppercase font-semibold"
                  href={item.url || ''}
                >
                  <span>{item.name}</span>

                  {!!item.items?.length && <LuChevronDown />}
                </Link>

                {!!item.items?.length && (
                  <div className="pt-4 absolute hidden group-hover:block -left-4 z-40">
                    <ul className="rigel-desktop-nav__primary-sub-menu-items bg-white top-8  pl-6 pr-12 py-2 shadow-lg border-t-4 border-primary text-sm @xl:text-base @3xl:text-lg">
                      {item.items.map((subItem) => (
                        <li key={subItem.name} className="rigel-desktop-nav__primary-menu-item">
                          <Link
                            prefetch={false}
                            className="w-auto py-4 flex flex-row gap-1 items-center uppercase"
                            href={subItem.url?.replace('/pages', '') || ''}
                          >
                            <span className="whitespace-nowrap">{subItem.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
            <div className="flex gap-5 items-center">
              <LinkButton
                buttonStyle={ButtonStyle.PrimaryBorder}
                buttonSize="large"
                href="/properties/sales"
                className={twMerge('flex items-center gap-2', CUSTOM_BUTTON_SIZES)}
              >
                <LuSearch />
                <span>Search Properties</span>
              </LinkButton>
              <LinkButton
                buttonStyle={ButtonStyle.Primary}
                buttonSize="large"
                href="/instant-valuation"
                className={CUSTOM_BUTTON_SIZES}
              >
                <span>Book a free valuation</span>
              </LinkButton>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
}
