import Link from 'next/link';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaPinterest, FaXTwitter } from 'react-icons/fa6';

import { SerializableAgency } from '@/serializers/agency';

export default function SocialIcons({
  agency,
  iconClassName,
}: {
  agency: SerializableAgency;
  iconClassName: string;
}) {
  return (
    <>
      {agency.preferences?.twitter_uri && (
        <Link
          prefetch={false}
          href={agency.preferences.twitter_uri}
          target="_blank"
          aria-label="X"
          passHref
        >
          <FaXTwitter className={iconClassName} />
        </Link>
      )}

      {agency.preferences?.instagram_uri && (
        <Link
          prefetch={false}
          href={agency.preferences.instagram_uri}
          target="_blank"
          aria-label="Instagram"
          passHref
        >
          <FaInstagram className={iconClassName} />
        </Link>
      )}

      {agency.preferences?.linkedin_uri && (
        <Link
          prefetch={false}
          href={agency.preferences.linkedin_uri}
          target="_blank"
          aria-label="LinkedIn"
          passHref
        >
          <FaLinkedinIn className={iconClassName} />
        </Link>
      )}

      {agency.preferences?.facebook_uri && (
        <Link
          prefetch={false}
          href={agency.preferences.facebook_uri}
          target="_blank"
          aria-label="Facebook"
          passHref
        >
          <FaFacebook className={iconClassName} />
        </Link>
      )}

      {agency.preferences?.pinterest_uri && (
        <Link
          prefetch={false}
          href={agency.preferences.pinterest_uri}
          target="_blank"
          aria-label="Pinterest"
          passHref
        >
          <FaPinterest className={iconClassName} />
        </Link>
      )}
    </>
  );
}
