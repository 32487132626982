export default function Phone({ className }: { className?: string }) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="title"
      className={className}
    >
      <title id="title">Phone</title>
      <path
        d="M16.9995 12.4791V14.8877C17.0004 15.1113 16.9545 15.3327 16.8648 15.5375C16.775 15.7424 16.6434 15.9263 16.4783 16.0775C16.3132 16.2286 16.1183 16.3437 15.906 16.4154C15.6938 16.487 15.4689 16.5136 15.2457 16.4935C12.7702 16.225 10.3923 15.3808 8.30312 14.0286C6.35937 12.7959 4.71141 11.1512 3.47627 9.21135C2.11669 7.11679 1.27059 4.73206 1.00653 2.25036C0.986426 2.02834 1.01286 1.80457 1.08416 1.5933C1.15546 1.38203 1.27005 1.1879 1.42065 1.02325C1.57124 0.858607 1.75454 0.72706 1.95886 0.636987C2.16319 0.546914 2.38407 0.500288 2.60744 0.500078H5.02086C5.41128 0.496243 5.78977 0.634222 6.0858 0.888297C6.38182 1.14237 6.57517 1.49521 6.62981 1.88103C6.73168 2.65185 6.92059 3.4087 7.19295 4.13713C7.30118 4.4245 7.32461 4.73682 7.26045 5.03707C7.19629 5.33732 7.04723 5.61292 6.83093 5.83121L5.80925 6.85087C6.95446 8.86092 8.62206 10.5252 10.6361 11.6682L11.6578 10.6485C11.8765 10.4326 12.1527 10.2839 12.4535 10.2198C12.7544 10.1558 13.0673 10.1792 13.3552 10.2872C14.0851 10.559 14.8435 10.7476 15.6158 10.8492C16.0066 10.9042 16.3635 11.1007 16.6186 11.4012C16.8737 11.7017 17.0093 12.0853 16.9995 12.4791Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
