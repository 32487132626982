import { ComponentPropsWithRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonStyle } from '@homeflow/next/state';

interface Props extends ComponentPropsWithRef<'button'> {
  buttonStyle?: ButtonStyle;
  buttonSize?: string;
}
const buttonBaseClasses = 'flex items-center justify-center gap-2 px-6 w-auto border font-bold';

const buttonPrimaryClasses =
  'uppercase border-button-primary bg-button-primary text-button-text hover:bg-button-tint hover:text-button-tint-text';

const buttonPrimaryBorderClasses =
  'uppercase border-button-primary fill-primary stroke-primary text-primary hover:bg-button-primary hover:text-button-text';

const buttonWhiteBorderClasses =
  'border-grey-alternative bg-button-tint text-button-tint-text fill-button-tint-text stroke-button-tint-text hover:bg-button-primary hover:text-button-text hover:stroke-button-text hover:fill-button-text';

const buttonStyleClasses = (buttonStyle?: ButtonStyle) => {
  switch (buttonStyle) {
    case 'primary':
      return buttonPrimaryClasses;
    case 'primaryBorder':
      return buttonPrimaryBorderClasses;
    case 'whiteBorder':
      return buttonWhiteBorderClasses;
    default:
      return buttonPrimaryClasses;
  }
};

const buttonSizeClasses = (buttonSize?: string) => {
  return buttonSize === 'large' ? 'py-3.5 text-base' : 'py-2 text-sm';
};

export const buttonClasses = (buttonStyle?: ButtonStyle, buttonSize?: string) => {
  return twMerge(buttonBaseClasses, buttonStyleClasses(buttonStyle), buttonSizeClasses(buttonSize));
};

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, buttonStyle, buttonSize, children, ...otherProps }: Props, ref) => {
    return (
      <button
        className={twMerge(buttonClasses(buttonStyle, buttonSize), className)}
        ref={ref}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
