import { SerializableBranch } from '@/serializers/branch';

export default function MobileNavBranchItem({ branch }: { branch: SerializableBranch }) {
  const telephone = branch.contactTelephone || branch.salesTelephone || branch.lettingsTelephone;
  return (
    <div className="rigel-mobile-nav-sidebar-item py-2">
      <div>{branch.name}</div>
      <a href={`tel:${telephone}`} className="text-primary font-bold underline">
        {telephone}
      </a>
    </div>
  );
}
