'use client';
import { useState } from 'react';
import Link from 'next/link';
import { LuChevronDown } from 'react-icons/lu';
import { twMerge } from 'tailwind-merge';

import { MenuItem } from '@homeflow/next/models';

const PRIMARY_ITEM_STYLES = 'mobile-nav__primary-item flex w-full py-2.5 uppercase text-base';

export default function MobileNavItem({ item }: { item: MenuItem }) {
  const { items, name, url } = item;

  const [open, setOpen] = useState(false);

  return (
    <div className="rigel-mobile-nav-sidebar-item">
      {!items ||
        (items.length === 0 && url && (
          <Link
            prefetch={false}
            href={url}
            className={twMerge(PRIMARY_ITEM_STYLES, 'font-semibold')}
          >
            {name}
          </Link>
        ))}

      {items?.length > 0 && (
        <>
          <button
            className={twMerge(PRIMARY_ITEM_STYLES, 'font-semibold')}
            type="button"
            onClick={() => setOpen((prev) => !prev)}
          >
            {name}
            <LuChevronDown
              className={twMerge(
                'h-6 w-6 origin-center rotate-0 ease-in-out duration-300 text-primary',
                open && '-rotate-180'
              )}
            />
          </button>

          <div
            className={twMerge(
              'transition-all pl-4 grid',
              !open && 'grid-rows-[0fr]',
              open && 'grid-rows-[1fr]'
            )}
          >
            <ul className="overflow-hidden">
              {items.map((subItem) => (
                <li key={subItem.name} className={PRIMARY_ITEM_STYLES}>
                  <Link prefetch={false} href={subItem.url || ''}>
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
