import { twMerge } from 'tailwind-merge';

export default function MobileNav({ shown }: { shown: boolean }) {
  return (
    <div className="w-11 h-11 relative">
      <div
        className={twMerge(
          'origin-[-11%_50%] bg-primary absolute left-0 top-0 h-1.5 w-full transition',
          shown && 'rotate-45'
        )}
      />

      <div
        className={twMerge(
          'bg-custom-7 absolute left-0 top-[19px] h-1.5 w-full opacity-1 transition',
          shown && 'opacity-0'
        )}
      />

      <div
        className={twMerge(
          'origin-[-11%_50%] bg-primary absolute left-0 top-[38px] h-1.5 w-full transition',
          shown && '-rotate-45'
        )}
      />
    </div>
  );
}
