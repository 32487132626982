'use client';

import Link from 'next/link';

import { AgencyMenu } from '@homeflow/next/models';

import { useUserProfile } from '@/hooks';
import { SerializableAgency } from '@/serializers/agency';
import { SerializableBranch } from '@/serializers/branch';

import Container from '@rigel/components/container.component';
import Phone from '@rigel/svgs/phone';

import MobileNav from './mobile/mobile-nav.component';
import DesktopNav from './desktop/desktop-nav.component';
import SocialIcons from './social-icons.component';
import Register from '../../svgs/register';

interface Props {
  menu: AgencyMenu;
  agency: SerializableAgency;
  branches: SerializableBranch[];
}

export default function MainNav({ menu, agency, branches }: Props) {
  const { openProfile } = useUserProfile();

  if (!agency) return;

  return (
    <div className="bg-site-header-background">
      <Container>
        <div className="flex justify-between h-10 items-center border-b border-slate-200 mb-4 text-sm md:text-base stroke-primary stroke-2 md:stroke-[1.5px]">
          <div className="flex gap-2 items-center">
            <div className="flex gap-0 items-center">
              <Phone className="h-[18px] w-[18px] mr-2 md:mr-3" />
              Call us
              <Link
                prefetch={false}
                className="text-primary font-bold ml-1"
                href={`tel:${agency.contactTelephone}`}
              >
                {agency.contactTelephone}
              </Link>
            </div>
            <div className="hidden md:flex gap-3 ml-12 text-primary">
              <SocialIcons agency={agency} iconClassName="h-[18px] w-[18px]" />
            </div>
          </div>
          <div className="flex gap-3 md:gap-9 text-sm print:hidden">
            {/* TODO: updating profile button to a hardcoded link as Rigel currently
             * has no user profile
             */}
            <Link
              href="/register"
              className="flex gap-2 items-center"
              aria-label="User profile button"
            >
              <Register className="h-[18px] w-[18px] text-primary" />
              <span className="hidden @md:block">Register your interest</span>
            </Link>

            {/* USER_PROFILE - Hide user profile for the time being
            <button
              className="flex gap-2 items-center"
              onClick={openProfile}
              aria-label="User profile button"
            >
              <Heart className="h-3 md:h-4" />
              <span className="hidden @md:block">My saved properties</span>
            </button>
            */}
          </div>
        </div>
      </Container>

      <MobileNav menu={menu} agency={agency} branches={branches} />
      <DesktopNav menu={menu} agency={agency} />
    </div>
  );
}
