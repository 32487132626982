import { ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {}

export default function Register(props: Props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 17.2292V18C15 19.1046 14.1046 20 13 20H3C1.89543 20 1 19.1046 1 18V3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V6.86968"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.1036 16.6911H10.2819V14.9108L17.1783 8.17114L19 9.95143L12.1036 16.6911Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 5H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4 8H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4 11H9.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4 14H7.55556" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
